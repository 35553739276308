exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-contentful-blog-post-slug-js": () => import("./../../../src/pages/blog/{contentfulBlogPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-tag-contentful-tag-contentful-id-js": () => import("./../../../src/pages/blog/tag/{contentfulTag.contentful_id}.js" /* webpackChunkName: "component---src-pages-blog-tag-contentful-tag-contentful-id-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contentful-city-city-name-js": () => import("./../../../src/pages/{contentfulCity.cityName}.js" /* webpackChunkName: "component---src-pages-contentful-city-city-name-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-hijri-date-js": () => import("./../../../src/pages/hijri-date.js" /* webpackChunkName: "component---src-pages-hijri-date-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

